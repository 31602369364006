<template>
  <div>
    <b-card class="characteristics text-center h-100">

      <font-awesome-icon
        :icon="['fas', icon]"
        class="text-primary mb-3"
        size="3x"
      />

      <h3> {{ title }} </h3>

      <div v-if="subtitle">
        <hr class="solid">

        <p>
          {{ subtitle }}
        </p>
      </div>

    </b-card>
  </div>
</template>

<script>

import { BCard } from 'bootstrap-vue'

export default {
  name: 'Card',
  components: {
    BCard,
  },
  props: {
    icon: {
      type: String,
      default: null,
      required: true,
    },
    title: {
      type: String,
      default: null,
      required: true,
    },
    subtitle: {
      type: String,
      default: null,
      required: false,
    },
  },
}
</script>

<style scoped>

  h3 {
    font-size: 30px;
  }

  .characteristics {
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }

  hr.solid {
    border-top: 2px solid #999;
    margin-top: 1px;
  }

  @media (min-width: 1200px) and (max-width: 1400px){
    p {
      font-size: 16px !important;
    }

    h3 {
      font-size: 25px;
    }
  }

  @media (max-width: 700px){
    p {
      font-size: 15px !important;
    }

    h3 {
      font-size: 23px !important;
    }
  }

</style>
