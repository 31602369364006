<template>
  <div class="auscultation">
    <!-- Banner -->
    <banner
      :image="data.bannerImage"
      :video="data.bannerAuscultationVideo"
      :title="$t('auscultation.bannerTitle')"
      height="600"
    />

    <!-- Monitoring -->
    <monitoring />

    <!-- Incidences -->
    <incidences />

    <!-- Dynamic Auscultation -->
    <dynamic />

    <!-- Main Data -->
    <main-data />
  </div>
</template>

<script>

import Banner from 'Components/Common/Banner.vue'
import Monitoring from 'Components/Auscultation/Monitoring.vue'
import Incidences from 'Components/Auscultation/Incidences.vue'
import Dynamic from 'Components/Auscultation/Dynamic.vue'
import MainData from 'Components/Auscultation/MainData.vue'

import MyData from 'Data/imas.json'

export default {
  name: 'Auscultation',
  components: {
    Banner,
    Monitoring,
    Incidences,
    Dynamic,
    MainData,
  },
  data() {
    return {
      data: MyData.data,
    }
  },
}
</script>
