<template>
  <div class="my-5 container text-center">

    <!-- Title -->
    <h3 class="mb-4">
      {{ $t('auscultation.dynamicTitle') }}
    </h3>

    <!-- Objective -->
    <b-row class="my-5">

      <template v-for="item in $t('auscultation.objective')">
        <b-col
          :key="item.number"
          class="mb-4"
          lg="6"
          md="12"
        >
          <div class="objective">
            <h1>
              {{ item.number }}
            </h1>
            <p>
              {{ item.text }}
            </p>
          </div>

        </b-col>
      </template>
    </b-row>

    <!-- Auscultation Dynamic -->

    <h3 class="mb-4">
      {{ $t('auscultation.auscultationDynamic') }}
    </h3>

    <b-img-lazy
      src="/static/limmat-images/auscultation/auscultationDynamic.png"
      alt="Auscultation Image"
      class="mb-4"
      fluid
    />

  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BImgLazy,
} from 'bootstrap-vue'

export default {
  name: 'Dynamic',
  components: {
    BRow,
    BCol,
    BImgLazy,
  },
}
</script>

<style scoped>
    .objective{
        border-width: 1px;
        border-style: solid;
        border-color: #ff8f00;
        padding-right: 10px;
        padding-left: 10px;
        margin-right: 40px;
        margin-left: 40px;
    }

    .objective:hover {
        border-color: black;
    }

    h1:hover {
        color: black !important;
    }
</style>
