<template>
  <div class="my-5 container">

    <!-- Title -->
    <h3 class="mb-4 text-center">
      {{ $t('auscultation.mainDataTitle') }}
    </h3>

    <b-row class="mb-3 mainData">

      <template v-for="item in $t('auscultation.mainData')">
        <b-col
          :key="item.text"
          class="mb-3"
          lg="6"
          md="12"
        >

          <p style="font-weight: bold;">
            <font-awesome-icon
              :icon="['fas', 'plus']"
              class="text-primary"
            />
            {{ item.text }}
          </p>

        </b-col>
      </template>
    </b-row>

  </div>
</template>

<script>

import {
  BRow,
  BCol,
} from 'bootstrap-vue'

export default {
  name: 'MainData',
  components: {
    BRow,
    BCol,
  },
}
</script>

<style scoped>
    h3 {
        color: #ff8f00 !important;
    }

    h4 {
        color: black !important;
    }

    @media (min-width: 700px){
      .mainData {
        margin-left: 70px;
      }
    }

</style>
