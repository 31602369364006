<template>
  <div class="my-5 container">

    <b-row class="text-center my-5">
      <b-col
        lg="6"
        md="12"
      >
        <!-- Title -->
        <h1 class="display-2 text-center mb-4">
          {{ $t('auscultation.monitoringTitle') }}
        </h1>

        <!-- Subtitle -->
        <p>
          {{ $t('auscultation.monitoringSubtitle') }}
        </p>
      </b-col>

      <b-col
        lg="6"
        md="12"
      >
        <!-- Image -->
        <b-img-lazy
          src="/static/limmat-images/auscultation/monitoring.png"
          alt="Frequency Image"
          style="width: 100%"
        />
      </b-col>
    </b-row>

    <!-- Advantages -->
    <b-row class="mb-3">

      <template v-for="item in $t('auscultation.advantages')">
        <b-col
          :key="item.title"
          class="mb-4"
          lg="6"
          md="12"
        >

          <card
            :icon="item.icon"
            :title="item.title"
            :subtitle="item.desc"
          />

        </b-col>
      </template>
    </b-row>

  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BImgLazy,
} from 'bootstrap-vue'

import Card from 'Components/Common/Card.vue'

export default {
  name: 'Monitoring',
  components: {
    BRow,
    BCol,
    BImgLazy,
    Card,
  },
}
</script>
