<template>
  <div class="my-5 container">

    <!-- Title -->
    <h3
      class="text-center mb-4"
    >
      {{ $t('auscultation.incidencesTitle') }}
    </h3>

    <!-- Subtitle -->
    <p>
      {{ $t('auscultation.incidencesSubtitle') }}
    </p>

    <!-- Incidences -->
    <b-row class="my-5 text-center">

      <template v-for="item in $t('auscultation.incidences')">
        <b-col
          :key="item.title"
          class="mb-4"
          lg="4"
          md="12"
        >
          <font-awesome-icon
            :icon="['fas', item.icon]"
            class="text-primary mb-3"
            size="3x"
          />

          <p style="font-weight: bold;">
            {{ item.title }}
          </p>

        </b-col>
      </template>
    </b-row>

  </div>
</template>

<script>

import {
  BRow,
  BCol,
} from 'bootstrap-vue'

export default {
  name: 'Incidences',
  components: {
    BRow,
    BCol,
  },
}
</script>
